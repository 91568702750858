module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.20.0_jiti@1_vkuzd73p7oaebvnbcx4spxx3le/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NZXXSQC","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.20.0_jiti@1.21.6___react-dom@18.3.1_react@18.3.1___xewlu5oqyj5ox3book5ko6as4m/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
